@font-face {
  font-family: ProximaNova;
  src: url("../assets/fonts/ProximaNova-Regular.otf");
}
@font-face {
  font-family: CS;
  src: url("../assets/fonts/Century-Schoolbook-L-Regular.ttf");
}

$accept: rgb(85, 182, 0);
$theBlue: #008cff;

body {
  font-family: sans-serif;
}

#houseHoldDisplayed * {
  background-color: white;
  color: black;
}

header {
  height: 100vh;
}

#checkEstate {
  background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25)),
    url("../assets/images/mainImg.png") center center;
  background-size: cover;
  top: 0;
  margin-top: 0;
  border-top: 0;
  padding-top: 0;
  width: 100%;
  height: 50vh;
  position: relative;
}

.logo {
  color: white !important;
  font-size: 25px;
  display: flex;
  flex-direction: row;
}

.oLogoSign {
  color: rgb(0, 199, 0) !important;
}

#underHeader {
  font-size: 15px;
  text-align: center;
}

.readyTitle {
  font-size: 52px;
  text-align: center;
  font-family: sans-serif;
}

.headerLinks {
  margin: auto auto 70px auto;
  width: 40%;
  display: grid;
  grid-template-columns: 30% 40% 30%;
}

.headerLinks a {
  padding: 10px 20px;
  text-decoration: none;
}

.headerRow {
  display: flex;
  width: 400px;
  margin-left: auto;
  margin: auto 0 0 100%;
}

#checkEstate * {
  color: white;
}

#addressInfo {
  text-align: center;
  height: max-content;
}

#threeSteps {
  text-align: center;
  font-size: 42px;
}

#checkPlace {
  display: grid;
  grid-template-columns: auto auto;
  text-align: center;
  margin: 30px auto 0 auto;
  overflow: hidden;
  justify-content: center;
}

#inputInfo {
  width: auto;
}

#addressDisplay {

}

#checkEstateInput {
  width: 300px;
  border-radius: 10px 0 0 10px;
  padding: 20px 15px;
  background-size: 20px 20px;
  background-color: white;
}

#checkEstateInput[type="text"] {
  padding-left: 30px;
  background-color: white;
  height: 60px;
  font-size: 15px;
  line-height: 30px;
}

#infoInput {
  display: flex;
  flex-direction: column;
}

#checkEstateButton {
  border-radius: 0 10px 10px 0;
  padding: 2px 15px;
  height: 60px;
  line-height: 30px;
  font-size: 15px;
  background-color: rgb(0, 140, 255);
  border: none;
  border-color: 22px white;
  color: white;
  caret-color: rebeccapurple !important;
}

.errorMessage {
  text-align: center;
}

#sellOffer {
  font-size: 15px;
  background-color: rgb(0, 140, 255);
  padding: 20px;
  color: white;
  border-radius: 15px;
  border: 5px solid $theBlue;
  display: block;
  margin: 50px auto 0 auto;
}

#sellCost {
  display: block;
  align-self: center;
  justify-self: center;
  font-size: 30px;
  white-space: pre-wrap;
  margin: 70px auto;
}

.emphasizeTxt {
  font-size: 45px !important;
  font-weight: 700;
}

body {
  caret-color: transparent;
}

input {
  outline: none;
  caret-color: black !important;
}

input::-moz-focus-inner {
  border: 0;
}

#addressDisplay {
  color: white;
  background-color: $theBlue;
  border-radius: 0 0 20px 20px;
  padding: 20px;
  width: 100%;
  margin: auto;
}

.divBackground * {
  text-align: center;
}

#checkEstateTitle,
.description,
#estimationOfHouseTitle,
#searchHouseTitle {
  text-align: center;
}

h5 {
  font-size: 20px;
}

#equalSign {
  margin: auto 50px;
}

#numberExpression {
  font-weight: 999;
  font-size: larger;
}

.row {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: auto;
}

#searchHouseTitle {
  white-space: pre-wrap;
  margin: auto;
  text-align: center;
}

.rowSplit {
  margin: -30px auto;
}

#hoverInfo:hover {
  cursor: pointer;
  color: rgba(255, 251, 0, 0.5);
}

.iconImgs {
  display: block;
  width: auto;
  height: auto;
  margin: auto auto 0 0;
  border-radius: 15px;
}

.column {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin: 30px;
}

#buyHome {
  width: 40%;
  height: 40%;
}

.descriptionHouse {
  width: 85%;
  margin: 0 30px;
}

#searchHouse {
  display: flex;
  flex-direction: row;
  width: 70%;
  margin: auto;
}

#searchHouseBtn {
  width: 35%;
  border-radius: 25px;
  border: 2px solid black;
  margin: 100px auto auto auto;
}

.backgroundSell {
  margin-top: 200px;
  text-align: center;
  background: linear-gradient(rgba(0, 140, 255, 0.7), rgba(0, 140, 255, 0.7)),
    url("../assets/images/backgroundSell.png") center center;
  background-size: contain 100%;
  width: 100%;
  height: 50vh;
  overflow: hidden;
}

.backgroundSell * {
  color: white;
  font-size: 30px;
}

.brokerInfo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
}

#containTxt {
  margin: 0;
}

.flexMeglerTxt {
  display: flex !important;
  flex-direction: row;
  text-align: center;
  align-content: center;
  justify-content: center;
  margin-bottom: 20px;
}

.flexMeglerTxt > .moveRight,
.moveLeft {
  margin: 0 22px;
}

.moveRight {
  white-space: pre-wrap;
}

.moveLeft {
  white-space: pre-wrap;
}

.largeFont {
  font-size: larger;
}

.largestFont {
  display: flex;
  flex-direction: column;
  margin: auto 0;
}

.flexMeglerTxt > .largestFont {
  margin: 0 22px;
}

#readMore {
  background-color: $theBlue;
  border: $theBlue;
  border-radius: 15px;
  padding: 15px;
  color: white;
}

#selectSelvsolgt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  height: 400px;
  margin: auto;
  background-color: lightgrey;
}

#selectSelvsolgtColumn {
  display: flex;
  flex-direction: column;
  margin: auto 30px auto auto;
}

.sSImg {
  margin: auto auto auto 30px;
}

#readMore {
  width: 150px;
  margin: 10px auto;
}

#whySelect {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items:center;
  justify-content:center;
}

.logoAsk {
  height: 1.5em;
}

#whySelectTxt {
  white-space: pre-wrap;
}

#mouth {
  color: green;
}

#decide {
  color: $theBlue;
}

#yourHouse {
  color: black;
}

#dHTh4 {
  font-size: 40px;
}

#decideHouseTitle * {
  text-align: center;
}

#variousOption {
  text-align: center;
  font-size: 24px;
  font-weight: 200;
}

#tilbud {
  font-weight: 700 !important;
}

#google,
#fb {
  width: 50px;
  height: 50px;
}

.tableInfo {
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-self: center;
  margin: auto;
  max-width: fit-content;
}

.columnInfo {
  text-align: center;
  align-self: center;
  justify-self: center;
  align-self: center;
  margin: 0;
}

.tableInfoHeaders {
  text-align: center;
  height: 25px;
  white-space: pre-wrap;
  border-bottom: 2px solid lightgrey;
  font-weight: 700;
  font-size: larger;
  color: $theBlue;
}

.columnContent {
  font-weight: 900;
  border-bottom: 2px solid lightgrey;
}

.changeFont {
  padding:52.5px 70px !important;
  font-weight: 200 !important;
}

h4 {
  padding: 0 15px;
  color: gray;
}

.columnInfo > .columnContent,
.tableInfoHeaders {
  padding: 50px 70px;
}

#frequentlyAsked {
  text-align: center;
  background-color: lightgrey;
  padding: 20px 0;
  margin-top: 100px;
  width: 100%;
}

.faTitle {
  margin-top: 20px;
}

.faBar {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 5px;
  border-radius: 15px;
  width: 600px;
  margin: 30px auto;
}

.faBar:hover {
  cursor: pointer;
}

.faQuestion {
}

#contactDiv {
    display: flex;
    flex-direction: row;
}

#contact {
    width: 50%;
    text-align: center;
}

#countyDiv {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-template-columns: [linename] 200px;
  grid-template-columns: [linename1] 200px [linename2 linename3];
  grid-template-columns: minmax(12 1fr);
  grid-template-columns: fit-content(40%);
  grid-template-columns: repeat(2, 300px);
  grid-template-columns: subgrid;
  grid-template-columns: masonry;
  align-content: center;
  justify-content: center;
}

.county {
  padding: 6px 8px;
  text-align: center;
  border-radius: 15px;
  margin: 20px;
  border: 1px solid lightgrey;
}

.county:hover {
    cursor: pointer;
}

.boliger{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  padding: 0 10px;
  width: 50%;
  margin: auto;
  justify-content: center;
}


#steder {
    font-size: 30px;
    text-align: center;
}

#mail {
    border: 1px solid lightgrey;
    border-radius: 15px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 25px 0 auto;
}

#mail * {
   margin: 0 auto 15px auto;
}

#expertHelp {
    color: black;
    font-size: 32px;
}

#emailAdress {
    border: 1px solid lightgrey;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
}

.tableInfoResponsive {
  display: none;
}

footer {
  border-top: 3px solid rgb(238, 238, 238);
  height: 300px;
  margin: 100px auto auto auto;
}

#footerContent {
    width: 70%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-self: center;
    justify-self: center;
    white-space: pre-wrap;
}

#footerFlex {
  margin: auto 20px;
}

.selvsolgtFooter {
  text-align: center;
}

.footerGrid h5 {
  color: black;
  font-size: 18px;
}

.footerGrid h4 {
    text-decoration: none !important;
    padding: 0 !important;
}

a {
  text-decoration: none;
}

.footerGrid {
  margin: 0 50px;
}

.socialMedia {
  width: 200px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-self: center;
}

.socialMediaIcons {
  width: 70px;
  height: 50px;
  margin: auto;
}

#copyright {
  text-align: center;
}

.selvsolgtFooter {
  width:80%;
}

#infoSelvsolgt {
  width: 80%;
}











.container{
  display: block;
  position: relative;
  margin: 40px auto;
  height: auto;
  width: 700px;
  padding: 20px;
}

.containerHeader {
	color: #047700;
}

.container ul{
  list-style: none;
  margin: 0;
  padding: 0;
	overflow: auto;
}

ul li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  width: 100%;
	border-bottom: 1px solid #333;
}

ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

ul li input[type=checkbox]{
  position: absolute;
  visibility: hidden;
}

ul li label{
  width: 500px;
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 25px 25px 25px 80px;
  margin: auto;
  height: 20px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label{
	color: green;
}

ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  top: 22px;
  left: 20px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

ul li:hover .check {
  border: 5px solid green;
}

ul li .check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
	left: 5px;
  margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
  border: 5px solid #0DFF92;
}

input[type=checkbox]:checked ~ .check {
  border: 5px solid #0DFF92;
}

input[type=radio]:checked ~ .check::before{
  background: #0DFF92;
}

input[type=checkbox]:checked ~ .check::before{
  background: #0DFF92;
}


input[type=radio]:checked ~ label{
  color: #0DFF92;
}

input[type=checkbox]:checked ~ label{
  color: #0DFF92;
}

.signature {
	margin: 10px auto;
	padding: 10px 0;
	width: 100%;
}

.signature p{
	text-align: center;
	font-family: Helvetica, Arial, Sans-Serif;
	font-size: 0.85em;
	color: #AAAAAA;
}

.signature .much-heart{
	display: inline-block;
	position: relative;
	margin: 0 4px;
	height: 10px;
	width: 10px;
	background: #AC1D3F;
	border-radius: 4px;
	-ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.signature .much-heart::before, 
.signature .much-heart::after {
	  display: block;
  content: '';
  position: absolute;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #AC1D3F;
  top: -4px;
}

.signature .much-heart::after {
	bottom: 0;
	top: auto;
	left: -4px;
}

.signature a {
	color: #AAAAAA;
	text-decoration: none;
	font-weight: bold;
}


/* Styles for alert... 
by the way it is so weird when you look at your code a couple of years after you wrote it XD */

.alert {
	box-sizing: border-box;
	background-color: #BDFFE1;
	width: 100%;
	position: relative; 
	top: 0;
	left: 0;
	z-index: 300;
	padding: 20px 40px;
	color: #333;
}

.alert h2 {
	font-size: 22px;
	color: #232323;
	margin-top: 0;
}

.alert p {
	line-height: 1.6em;
	font-size:18px;
}

.alert a {
	color: #232323;
	font-weight: bold;
}

.contact-form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: auto;
}

.contactInput {
  width: 300px;
  margin: 30px auto;
  padding: 20px;
  border-radius: 15px;
  border: 2px solid lightgray;
  display: flex;
  flex-direction: column;
}

#sendEmail {
  display: block;
  margin: 50px auto;
  background-color: green;
  padding: 20px 40px;
  color: white;
  border-radius: 15px;
  border: none;
}

.placeOfLiving {
  font-size: 54px;
  background-color: red;
}

p {
  text-align: center;
}

.contact-form button {
  width: 200px;
  margin: 30px auto;
  padding: 15px 10px;
  border-radius: 25px;
  border: 2px solid lightgray;
  background-color: green;
  color: white;
}
.headersOffer {
  margin: 5px 0;
  font-size: 24px;
  text-align: center;
}
#offerSite {
  margin: auto;
}
.offerInfo {
  text-align: center;
  background-color: rgb(169, 238, 255);
  border: 2px solid black;
  width: 350px;
  margin: 10px auto;
  padding: 10px;
  border-radius: 15px;
}

.offerInfo label {
  margin-top: 5px;
}

#justCenter {
  text-align: center;
}

.placeOfLiving{
  cursor: pointer;
  background-color: rgb(169, 238, 255);
  font-size: 24px;
  border-radius: 15px;
  padding: 10px 15px;
  border: 3px solid black;
  margin: 20px auto;
  width: fit-content;
}

.placeOfLiving:hover {
  background-color: rgb(204, 198, 253);
  color: #0DFF92;
}

#theFloors {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;
  width: auto;
  margin: 10px auto;
  justify-content: center;
  grid-auto-columns: auto;
  grid-auto-flow: row;
}

.makeColumn {
  display: flex;
  flex-direction: column;
}

.offerInfo {
  display: flex;
  flex-direction: column;
}

.offerInfo > input, label {
   width: 280px;
   margin: auto;
}

.inputUserInfo {
  margin-top: 10px !important;
  padding: 10px;
  border-radius: 15px;
}
