@font-face {
    font-family: ProximaNova;
    src: url("../assets/fonts/ProximaNova-Regular.otf");
  }
  @font-face {
    font-family: CS;
    src: url("../assets/fonts/Century-Schoolbook-L-Regular.ttf");
  }
  
  $accept: rgb(85, 182, 0);
  $theBlue: #008cff;


  @media only screen and (max-width: 1560px) {
     .row {
         width: 100%;
     }

     #frequentlyAsked {
        height: auto;
    }

     .columnInfo > .columnContent, .tableInfoHeaders {
        padding: 20px 30px;
    }
    .tableInfoResponsive {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
      }
      .tableInfo {
        display: none;
        text-align: center;
      }
      .tableInfoHeadingResponsive * {
          text-align: center;
          color: $theBlue;
          font-size: 20px;
          margin-bottom: 10px;
      }
      .columnInfoResponsive {
          margin-top: 20px;
          border-bottom: 2px solid rgb(236, 236, 236);
          width: 100%;
          margin: 20px auto;
          text-align: center;
      }
      .tableInfoHeadingResponsive {
        border-bottom: 2px solid rgb(236, 236, 236);
        width: 100%;
      }
      .columnHeaderResponsive {
          font-weight: 300;
      }
      .columnContentResponsive {
          font-weight: 600;
      }
      .columnContentResponsive:last-child {
          margin-bottom: 40px;
      }
      .rowSplit {
        padding: 10px;
    }
    .rowSplit h5 {
        font-size: 15px;
    }

     #footerContent {
        width: 100%;
        flex-direction: column-reverse;
    }
    #footerContent *:not() {
        text-align: center;
    }
    .selvsolgtFooter {
      width:100%;
    }
    
    .footerGrid {
        margin-left: 45%;
    }

    #infoSelvsolgt {
      width: 100%;
      text-align: left !important;
    }
    #contactDiv {
        flex-direction: column;
        
    }
    #contact {
        align-items: center;
        justify-items: center;
        width: 100%;
    }
    #mail {
        align-items: center;
        justify-items: center;
        width: 100%;
        border: none;
    }
  }



@media only screen and (max-width: 800px) {
    
    #offerSite {
        width: 90%;
    }
    .contactInput {
       width: 80%;
    }
    header {
        height: 40vh;
    }

    .boliger{
        display: grid;
        grid-template-columns: auto auto auto;
        grid-gap: 5px;
        width: auto;
        margin: 10px auto;
        justify-content: center;
        grid-auto-columns:auto;
        grid-auto-flow: row;
      }

      ul li label {
        height: auto; 
    }

 

    .headerLinks {
        margin: auto;
        width: 100%;
        display: grid;
        grid-template-columns: auto auto auto;
        font-size: 10px;
    }

    .readyTitle {
        font-size: 25px;
    }
    #checkEstateInput {
        padding: 0 12px;
        font-size: 12px;
        height: 42px;
        border: none;
        display:inline;
        margin-right: auto;
    }
    #checkEstateButton {
        padding: 12px 6px;
        font-size: 12px;
        width: 150px;
        height: 42px;
        margin-left: auto;
        line-height: 12px;
        display:inline;
    }
    #selectSelvsolgtColumn {
        margin: auto;
    }
    #checkPlace {
        align-items: center;
        width: 90%;
    }
    #inputInfo {
        position: relative;
    }
    #threeSteps {
        font-size: 20px;
    }
    .row {
        flex-direction: column;
        width: auto;
    }
    .rowSplit {
          margin: auto;
    }
    h4 {
        font-size: 15px;
        margin: 5px auto;
    }
    h5 {
        font-size: 15px;
        margin: 5px auto;
    }
    .description {
        font-size: 13px;
        margin: 5px auto;
    }
    .iconImgs {
        margin-top: 15px;
    }
    .rowSplit {
        
    }
    #sellOffer {
        padding: 5px 10px;
        width: 70%;
    }
    .backgroundSell {
        margin-top: 1500px;
        height: 850px;
    }
    .brokerInfo {
        flex-direction: column;
    }
    #selectSelvsolgt{
        height: 500px;
        flex-direction: column;
    }
    #selectSelvsolgt *:not(#whySelectTxt) {
        justify-items: center;
        align-items: center;
        text-align: center;
    }
    #whySelectTxt {
        width: 95%;
        margin: auto 15px;
        text-align: left;
        white-space: inherit;
    }
    #readMore {
        margin-top: 15px;
    }
    .frequentlyAsked .sSImg {
        width: 30px;
        height: 30px;
        margin: 20px auto 0 auto;
    }

    .frequentlyAsked .sSImg {
        width: 30px;
        height: 30px;
        margin: 20px auto 0 auto;
    }
    #selectSelvsolgt .sSImg {
        width: 100%;
        margin: 20px auto 0 auto;
    }
    .faQuestion {
        margin: auto;
        justify-content: center;
        justify-self: center;
    }

      #dHTh4 {
          font-size: 24px;
      }
      #variousOption {
        font-size: 18px;
      }
      .faBar {
          width: 90%;
          margin: 30px auto;
      }
      ul li label{

        width: auto;
      }
      #countyDiv {
          grid-template-columns: auto;
      }
      #contact {
          width: 100%;
      }
      #mail {
          margin: auto;
          width: 95%;
          border: none;
      }
      #mail * {
          margin: 10px auto;
      }
      .footerGrid {
        margin-left: 40%;
    }

    .container {
        width: auto;
    }
  }

  @media only screen and (max-width: 658px) {
    #checkPlace {
        display: grid;
        grid-template-columns: auto auto;
        text-align: center;
        width: 90%;
        margin: 30px auto 0 auto;
        overflow: hidden;
        justify-content: center;
    }

    #checkEstateInput {
        width: 100%;
    }
  }

  @media only screen and (max-width: 450px) {
    #checkPlace {
        width: 100%;
    }
    .backgroundSell {
        margin-top: 1300px;
        height: 1000px;
    }

    .boliger {
        grid-template-columns: auto auto;
    }

  }

